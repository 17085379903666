import jwt_decode from "jwt-decode";

export enum LocalStorageItemKey {
  TOKEN = "token",
}

export class AuthService {
  static getToken(): string | null {
    const token: string | null = localStorage.getItem(
      LocalStorageItemKey.TOKEN
    );

    if (token) {
      return token;
    }

    return null;
  }

  private static removeToken(): void {
    localStorage.removeItem(LocalStorageItemKey.TOKEN);
  }

  static removeTokenAndRedirect(): void {
    // NOTE: localStorage has been updated correctly but such change is not detected inside <App/> therefore redirect is necessary
    this.removeToken();
    this.redirectToMainPage();
  }

  static isAuthenticated(): boolean {
    const urlParams: URLSearchParams = new URLSearchParams(
      window.location.search
    );
    const tokenParam: string | null = urlParams.get("token");

    if (tokenParam) {
      const isExpired = this.checkIfTokenIsExpired(tokenParam);

      if (isExpired) {
        // window.location.href.replace(window.location.search, "");
        this.removeTokenAndRedirect();
        this.redirectToMainPage();
        return false;
      } else {
        localStorage.setItem(LocalStorageItemKey.TOKEN, tokenParam);
        window.location.replace(window.location.pathname);
        return true;
      }
    }

    const token: string | null = localStorage.getItem(
      LocalStorageItemKey.TOKEN
    );

    if (token) {
      const isExpired = this.checkIfTokenIsExpired(token);

      if (isExpired) {
        this.removeTokenAndRedirect();
      } else {
        return true;
      }
    }

    return false;
  }

  static checkIfTokenIsExpired(token: string): boolean {
    const tokenDecoded: { exp: number; iat: number } = jwt_decode(token);

    const delta = tokenDecoded.exp - Math.floor(Date.now() / 1000);

    if (delta <= 0) {
      return true;
    } else {
      return false;
    }
  }

  static redirectToMainPage() {
    window.location.replace(`${window.location.origin}/home`);
  }
}
