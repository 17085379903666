import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../../../assets/logo-arteia-connect.svg";
import {
  ArrowImageWrapper,
  HeaderLinksContainer,
  StyledFlexDivider,
  StyledHeaderContainer,
  StyledHeaderLink,
  StyledHeaderLogo,
} from "./styles";

const tagsViewPathname = "/tags";

export function Header() {
  const location = useLocation();

  return (
    <StyledHeaderContainer>
      <HeaderLinksContainer>
        <StyledHeaderLink>
          {location.pathname.includes("/artwork") ? (
            <Link to={"/home"}>
              <ArrowImageWrapper>
                <img
                  src={
                    require("../../../../assets/icons/ico-arrow-left.svg")
                      .default
                  }
                  width={20}
                />
                <p>Back</p>
              </ArrowImageWrapper>
            </Link>
          ) : null}
        </StyledHeaderLink>
      </HeaderLinksContainer>

      <StyledHeaderLogo>
        <Logo />
        <img src={require("../../../../assets/app-logo.png")} width={40} />
      </StyledHeaderLogo>

      <StyledFlexDivider />
    </StyledHeaderContainer>
  );
}
