import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../../components/loader";
import {
  ArtworkConfigLocal,
  ArtworkConfigPopulated,
  JWTTokenDecoded,
  ViewState,
} from "../../models";
import { Props } from "./models";
import { v4 as uuid } from "uuid";
import {
  ArtworkContainer,
  ArtworkDataWrapper,
  LoaderWrapper,
  SingleArtwork,
} from "./styles";
import { Subscription } from "rxjs";
import { ArtworksService } from "../../services/artworks";
import { AuthService } from "../../services/auth";
import jwt_decode from "jwt-decode";
import { SingleArtworkAuthor, SingleArtworkTitle } from "../home/styles";

export const Artwork = (props: Props) => {
  const componentSubs = new Subscription();
  const { archetypeId } = useParams<{
    archetypeId: string;
  }>();

  const [viewState, setViewState] = useState<ViewState>(ViewState.LOADING);

  const [artwork, setArtwork] = useState<ArtworkConfigPopulated | null>(null);
  const [artworkIdAllowedToChange, setArtworkIdAllowedToChange] = useState<
    number | null
  >(null);

  useEffect(() => {
    if (props.artworksConfig && archetypeId) {
      const artwork = props.artworksConfig.find(
        (art) => art.id === +archetypeId
      );

      if (artwork) {
        setArtwork(artwork);
        setViewState(ViewState.OK);
      } else {
        setViewState(ViewState.ERROR);
      }
    } else {
      setViewState(ViewState.ERROR);
    }
  }, [archetypeId, props.artworksConfig]);

  useEffect(() => {
    const token = AuthService.getToken();
    if (props.isAuthenticated && token) {
      const tokenDecoded: JWTTokenDecoded = jwt_decode(token);
      setArtworkIdAllowedToChange(+tokenDecoded.sub);
    } else {
      setArtworkIdAllowedToChange(null);
    }
  }, [props.isAuthenticated]);

  const changeArtworkStatus = (newStatus: boolean) => {
    componentSubs.add(
      ArtworksService.changeArtworkStatus(newStatus).subscribe({
        next: () => {
          props.loadData();
        },
        error: (errorMessage: string) => {
          console.error("Unable to change artwork status", errorMessage);
        },
      })
    );
  };

  return (
    <ArtworkContainer>
      {viewState === ViewState.OK && artwork ? (
        <ArtworkDataWrapper>
          <SingleArtwork key={uuid()}>
            {artwork.animated ? (
              <div>
                <video
                  id={`${artwork.id}`}
                  muted
                  autoPlay
                  loop
                  // src={`../${artwork.videoUrl}`}
                  poster={`../${artwork.imageUrl}`}
                  src={artwork.videoUrl}
                  // poster={artwork.imageUrl}
                  // width="320"
                  width="100%"
                  height="100%"
                  style={{
                    maxWidth: "560px",
                  }}
                >
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </div>
            ) : (
              <div style={{ height: "100%", width: "100%" }}>
                <img
                  // src={`../${artwork.imageUrl}`}
                  src={artwork.imageUrl}
                  width="100%"
                  height="100%"
                  style={{
                    maxWidth: "560px",
                  }}
                />
              </div>
            )}
          </SingleArtwork>
          <div>
            <SingleArtworkTitle>{artwork.title}</SingleArtworkTitle>
            <SingleArtworkAuthor>{artwork.author}</SingleArtworkAuthor>
          </div>
          <div>
            {artwork.id === artworkIdAllowedToChange ? (
              <div onClick={() => changeArtworkStatus(!artwork.animated)}>
                {artwork.animated ? "Deactivate" : "Activate"}
              </div>
            ) : null}
          </div>
        </ArtworkDataWrapper>
      ) : null}

      {viewState === ViewState.ERROR ? (
        <div>Artwork with given id not found</div>
      ) : null}
      {viewState === ViewState.LOADING ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : null}
    </ArtworkContainer>
  );
};
