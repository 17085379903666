import styled from "styled-components";

const StyledHeaderContainer = styled.div`
  font-family: LatoBold;
  width: 100%;
  display: flex;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  // padding: 1.5rem 2.5rem;
  box-sizing: border-box;
  align-items: center;
`;

const StyledHeaderLogo = styled.div`
  flex-grow: 2;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;

const HeaderLinksContainer = styled.div`
  position: absolute;
  flex-grow: 1;
  padding: 0 0 7px 20px;
  z-index: 1;
`;

const StyledHeaderLink = styled.div`
  a {
    text-decoration: none;
  }
`;

const StyledFlexDivider = styled.div`
  flex: 1;
  padding-top: 1.6rem;
`;

const ArrowImageWrapper = styled.div`
  color: transparent;
  text-decoration: none;
  height: 20px;
`;
export {
  StyledHeaderContainer,
  StyledHeaderLogo,
  HeaderLinksContainer,
  StyledHeaderLink,
  StyledFlexDivider,
  ArrowImageWrapper,
};
