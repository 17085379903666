import React from "react";
import { Props } from "./model";
import { ReactComponent as LoaderIcon } from "../../assets/loader.svg";
import { StyledLoader } from "./styles";

export const Loader = (props: Props) => (
  <StyledLoader {...props}>
    <LoaderIcon />
  </StyledLoader>
);

Loader.defaultProps = {
  size: "default",
};
