import styled from "styled-components";

const StyledButton = styled.button`
  padding: 0 1.4em;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  transition: all 0.2s;
  color: #fff;
  background: linear-gradient(180deg, #3cddcd 0%, #12b4b5 100%);
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 10%);
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  border-width: 0;

  &:hover {
    box-shadow: 0 0 12px 0 rgb(20 75 99 / 28%);
    background: linear-gradient(180deg, #3cddcd 0%, #12b4b5 100%);
  }
`;

const StyledTextButton = styled.button`
  font-weight: bolder;
  color: rgb(0, 158, 160);
  cursor: pointer;
  background-color: transparent;
  border-width: 0;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

export { StyledButton, StyledTextButton };
