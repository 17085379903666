import { ArtworkConfigLocal } from "../models";

export const artworksConfig: ArtworkConfigLocal[] = [
  {
    id: 353,
    title: "Middle happy cloud 1, 2022",
    author: "Rachel De Joode",
    imageUrl:
      "https://storage.googleapis.com/artwork_images/upload_fe7f1ab08ad55ecae118038ec976efb6.jpg",
    videoUrl:
      "https://storage.googleapis.com/artwork_videos/upload_945bf17193637dac153187b525bef460.mp4",
    // Note: all other details
  },
  {
    id: 354,
    title: "Middle happy cloud 2, 2022",
    author: "Rachel De Joode",
    imageUrl:
      "https://storage.googleapis.com/artwork_images/upload_3e4c1d2761bd66f5e0e0a8a9e959ee3f.jpg",
    videoUrl:
      "https://storage.googleapis.com/artwork_videos/upload_95322124c9c032c6dd4e0527dd78f166.mp4",
    // Note: all other details
  },
  {
    id: 355,
    title: "Middle happy cloud 3 , 2022",
    author: "Rachel De Joode",
    imageUrl:
      "https://storage.googleapis.com/artwork_images/upload_605130908eb8fd0cfaf10f8f9705131e.jpg",
    videoUrl:
      "https://storage.googleapis.com/artwork_videos/upload_1cbbc6ad3de426cd0c6862713b54ae91.mp4",
    // Note: all other details
  },
  {
    id: 356,
    title: "Middle happy cloud 4 , 2022",
    author: "Rachel De Joode",
    imageUrl:
      "https://storage.googleapis.com/artwork_images/upload_07118b7db3ee6d58a1630c2de86f2aa2.jpg",
    videoUrl:
      "https://storage.googleapis.com/artwork_videos/upload_46240909039e659afc9dd3363bacfdb3.mp4",
    // Note: all other details
  },
];
