import React, { useEffect, useState } from "react";
import { redirect, Route, Routes, Navigate } from "react-router-dom";
import { Loader } from "../../components/loader";
import { artworksConfig } from "../../configs";
import {
  ArtworkConfigLocal,
  ArtworkConfigPopulated,
  JWTTokenDecoded,
} from "../../models";
import { AuthService } from "../../services/auth";
import { Artwork } from "../artwork";
import { Home } from "../home";
import { Header } from "./components/header";
import { AppContainer, LoaderWrapper } from "./styles";
import jwt_decode from "jwt-decode";
import { Subscription } from "rxjs";
import { ArtworksService } from "../../services/artworks";
import { ArtworkStatusData } from "../../services/artworks/models";

function App() {
  const componentSubs = new Subscription();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    AuthService.isAuthenticated()
  );

  const [displayConfig, setDisplayConfig] = useState<
    ArtworkConfigPopulated[] | null
  >(null);

  const loadData = () => {
    componentSubs.add(
      ArtworksService.getArtworksStatus().subscribe({
        next: (config) => {
          const mergedConfigs: ArtworkConfigPopulated[] = artworksConfig.map(
            (artworkConfig) => {
              const haveEqualId = (artworkStatus: ArtworkStatusData) =>
                artworkStatus.id === artworkConfig.id;
              const equalArtworkId = config.find(haveEqualId);
              return Object.assign({}, artworkConfig, equalArtworkId);
            }
          );

          setDisplayConfig(mergedConfigs);
        },
        error: (errorMessage: string) => {
          console.error("Unable to get artworks config", errorMessage);
        },
      })
    );
  };

  function authenticationHandler() {
    setIsAuthenticated(AuthService.isAuthenticated());
  }

  useEffect(() => {
    window.addEventListener("storage", authenticationHandler);

    return () => {
      // componentSubs.unsubscribe();
      window.removeEventListener("storage", authenticationHandler);
    };
  }, []);

  useEffect(() => {
    const token = AuthService.getToken();
    if (token) {
      const tokenDecoded: JWTTokenDecoded = jwt_decode(token);

      const delta = tokenDecoded.exp - Math.floor(Date.now() / 1000);

      console.log("Token will be active for", (delta / 60).toFixed(2), "min");

      const timer = setTimeout(() => {
        console.log("Token expired");
        AuthService.removeTokenAndRedirect();
      }, delta * 1000);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  // NOTE: need to set some kind of interval to check token

  return displayConfig ? (
    <AppContainer>
      <Header />
      <Routes>
        <Route
          path="/home"
          element={
            <Home
              artworksConfig={displayConfig}
              isAuthenticated={isAuthenticated}
              loadData={loadData}
            />
          }
        />

        <Route
          path="/artwork/:archetypeId"
          element={
            <Artwork
              artworksConfig={displayConfig}
              isAuthenticated={isAuthenticated}
              loadData={loadData}
            />
          }
        />

        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </AppContainer>
  ) : (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );
}

export default App;
